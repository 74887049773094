.logister {
  display: flex;
}

.cover {
  height: 100vh;
  width: 35vw;
  background-color: #fbc02d;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover .title {
  font-size: 50px;
}

.cover img {
  width: 80%;
}

.form {
  width: 65vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form form {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}

.form .socials {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.form .socials .icon-button {
  margin: 15px 0;
  height: 70px;
  width: 70px;
  border: solid 1px rgb(97, 97, 97);
  border-radius: 100%;
  font-size: 32px;
}

.form .socials .icon-button .icon {
  width: 40px;
  height: 40px;
}

.form .MuiFormControl-marginNormal {
  margin-top: 8px;
}

@media only screen and (max-width: 800px) {
  .logister {
    flex-direction: column;
  }
  .cover {
    min-height: 70px;
    height: auto;
    width: 100%;
    border-radius: 0 0 50px 50px;
  }
  .cover .title {
    font-size: 35px;
  }
  .cover h2 {
    display: none;
  }
  .cover img {
    display: none;
  }

  .form {
    height: 90vh;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .form form {
    padding-left: 0;
  }
}
