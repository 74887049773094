@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --accentColor: #fbc02d;
  --backgroundColor: #e0e0e0;
}

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  min-height: 70px;
  padding: 0 30px;
  background-color: #fbc02d;
  background-color: var(--accentColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0px 50px 50px;
}
.header a {
  color: #000;
  text-decoration: none;
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header .icon {
  color: black;
  font-size: 30px;
}

.title {
  color: black;
  font-size: 35px;
}

.notelist {
  margin: 0 10px;
}

.note {
  min-height: 100px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  margin: 10px;
  padding: 10px;
  border-radius: 9px;
  position: relative;
  box-shadow: 0 2px 10px rgb(153, 153, 153);
}

.note p,
.note h2,
.note .rdw-editor-wrapper {
  width: 70%;
}

.note .locked {
  position: absolute;
  height: 24px;
  width: 24px;
  align-items: center;
  right: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.note .color {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 17px;
  top: 20%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  border-radius: 12px;
}

.note-actions {
  position: absolute;
  right: 15px;
  bottom: 5px;
  color: gray;
  transition: 50ms linear;
  opacity: 0;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
}

.note-actions .icon:hover {
  color: #000;
}

.note:hover .note-actions {
  opacity: 1;
}

.noteDetail {
  padding: 20px;
  width: 100vw;
  height: 100vh;
}

.editor-wrapper {
  height: 95%;
}

.editor-body {
  height: 85%;
}

@media only screen and (max-width: 800px) {
  .header h5,
  .header h3 {
    display: none;
  }

  .note-actions {
    opacity: 1;
  }
}

.logister {
  display: flex;
}

.cover {
  height: 100vh;
  width: 35vw;
  background-color: #fbc02d;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover .title {
  font-size: 50px;
}

.cover svg {
  width: 80%;
}

.form {
  width: 65vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form form {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form .MuiFormControl-marginNormal {
  margin-top: 8px;
}

@media only screen and (max-width: 800px) {
  .logister {
    flex-direction: column;
  }
  .cover {
    min-height: 70px;
    height: auto;
    width: 100%;
    border-radius: 0 0 50px 50px;
  }
  .cover .title {
    font-size: 35px;
  }
  .cover h2 {
    display: none;
  }
  .cover svg {
    display: none;
  }

  .form {
    height: 90vh;
    width: 100%;
    text-align: center;
  }
}

.logister {
  display: flex;
}

.cover {
  height: 100vh;
  width: 35vw;
  background-color: #fbc02d;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover .title {
  font-size: 50px;
}

.cover img {
  width: 80%;
}

.form {
  width: 65vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form form {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}

.form .socials {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.form .socials .icon-button {
  margin: 15px 0;
  height: 70px;
  width: 70px;
  border: solid 1px rgb(97, 97, 97);
  border-radius: 100%;
  font-size: 32px;
}

.form .socials .icon-button .icon {
  width: 40px;
  height: 40px;
}

.form .MuiFormControl-marginNormal {
  margin-top: 8px;
}

@media only screen and (max-width: 800px) {
  .logister {
    flex-direction: column;
  }
  .cover {
    min-height: 70px;
    height: auto;
    width: 100%;
    border-radius: 0 0 50px 50px;
  }
  .cover .title {
    font-size: 35px;
  }
  .cover h2 {
    display: none;
  }
  .cover img {
    display: none;
  }

  .form {
    height: 90vh;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .form form {
    padding-left: 0;
  }
}

.color-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.color-picker .color {
  width: 64px;
  cursor: pointer;
  margin: 0px 12px;
}

.color-picker .color.selected .color-container {
  border: 5px solid black;
}

.color-picker .color.selected .color-title {
  font-weight: bold;
}

.color-picker .color-container {
  width: 64px;
  height: 64px;
  margin: 2px 0px;
  border-radius: 50%;
  position: relative;
  transition: border 200ms ease 0s;
}

.color-picker .color-title {
  text-align: center;
}

.color-picker .bubble {
  border-radius: 50%;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.user-details {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.user-details .body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-details .body .card {
  background-color: #d2d2d2;
  padding: 50px;
  width: 70%;
  height: 80%;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
}

.user-details .card .username {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}

.user-details .card .username label {
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
}

.user-details .card .username label .close-overlay {
  position: absolute;
  height: 25px;
  width: 25px;
  align-items: center;
  text-align: center;
  top: 2%;
  right: 2%;
  color: black;
  background-color: brown;
  border-radius: 100%;
  opacity: 0;
  transition: all 0.5s ease;
}

.user-details .card .username label:hover .close-overlay {
  opacity: 1;
}

.user-details .username input[type="file"] {
  display: none;
}

.user-details .username .avatar {
  width: 75px;
  height: 75px;
  display: flex;
}

.user-details .username .h1eh2 {
  width: 100%;
  text-align: center;
}

.user-details .card .button-group {
  margin-top: 20px;
}

.user-details .card .logout-btn {
  margin-top: 20px;
  color: white;
  background-color: crimson;
}

.user-details .card .MuiFormControlLabel-root .MuiTypography-root {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .user-details .body .card {
    width: 80%;
    align-items: center;
  }

  .user-details .card .username {
    flex-direction: column;
    justify-content: center;
  }

  .user-details .card .username .h1eh2 {
    margin-top: 20px;
    text-align: center;
    padding-left: 0;
  }

  .user-details .card .username label .close-overlay {
    opacity: 1;
  }
}

