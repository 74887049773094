@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --accentColor: #fbc02d;
  --backgroundColor: #e0e0e0;
}

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  min-height: 70px;
  padding: 0 30px;
  background-color: var(--accentColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0px 50px 50px;
}
.header a {
  color: #000;
  text-decoration: none;
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header .icon {
  color: black;
  font-size: 30px;
}

.title {
  color: black;
  font-size: 35px;
}

.notelist {
  margin: 0 10px;
}

.note {
  min-height: 100px;
  user-select: none;

  margin: 10px;
  padding: 10px;
  border-radius: 9px;
  position: relative;
  box-shadow: 0 2px 10px rgb(153, 153, 153);
}

.note p,
.note h2,
.note .rdw-editor-wrapper {
  width: 70%;
}

.note .locked {
  position: absolute;
  height: 24px;
  width: 24px;
  align-items: center;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.note .color {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 17px;
  top: 20%;
  transform: translate(0, -50%);
  border-radius: 12px;
}

.note-actions {
  position: absolute;
  right: 15px;
  bottom: 5px;
  color: gray;
  transition: 50ms linear;
  opacity: 0;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
}

.note-actions .icon:hover {
  color: #000;
}

.note:hover .note-actions {
  opacity: 1;
}

.noteDetail {
  padding: 20px;
  width: 100vw;
  height: 100vh;
}

.editor-wrapper {
  height: 95%;
}

.editor-body {
  height: 85%;
}

@media only screen and (max-width: 800px) {
  .header h5,
  .header h3 {
    display: none;
  }

  .note-actions {
    opacity: 1;
  }
}
