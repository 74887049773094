.user-details {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.user-details .body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-details .body .card {
  background-color: #d2d2d2;
  padding: 50px;
  width: 70%;
  height: 80%;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
}

.user-details .card .username {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}

.user-details .card .username label {
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
}

.user-details .card .username label .close-overlay {
  position: absolute;
  height: 25px;
  width: 25px;
  align-items: center;
  text-align: center;
  top: 2%;
  right: 2%;
  color: black;
  background-color: brown;
  border-radius: 100%;
  opacity: 0;
  transition: all 0.5s ease;
}

.user-details .card .username label:hover .close-overlay {
  opacity: 1;
}

.user-details .username input[type="file"] {
  display: none;
}

.user-details .username .avatar {
  width: 75px;
  height: 75px;
  display: flex;
}

.user-details .username .h1eh2 {
  width: 100%;
  text-align: center;
}

.user-details .card .button-group {
  margin-top: 20px;
}

.user-details .card .logout-btn {
  margin-top: 20px;
  color: white;
  background-color: crimson;
}

.user-details .card .MuiFormControlLabel-root .MuiTypography-root {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .user-details .body .card {
    width: 80%;
    align-items: center;
  }

  .user-details .card .username {
    flex-direction: column;
    justify-content: center;
  }

  .user-details .card .username .h1eh2 {
    margin-top: 20px;
    text-align: center;
    padding-left: 0;
  }

  .user-details .card .username label .close-overlay {
    opacity: 1;
  }
}
