.color-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.color-picker .color {
  width: 64px;
  cursor: pointer;
  margin: 0px 12px;
}

.color-picker .color.selected .color-container {
  border: 5px solid black;
}

.color-picker .color.selected .color-title {
  font-weight: bold;
}

.color-picker .color-container {
  width: 64px;
  height: 64px;
  margin: 2px 0px;
  border-radius: 50%;
  position: relative;
  transition: border 200ms ease 0s;
}

.color-picker .color-title {
  text-align: center;
}

.color-picker .bubble {
  border-radius: 50%;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
